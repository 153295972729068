import { ListItemText } from "@mui/material";
import { Link, useNavigate, useNavigation } from "react-router-dom";

export function SearchLink({
  search,
  interval,
}: {
  search: string;
  interval?: string;
}): JSX.Element {
  const navigate = useNavigate();

  const handleNavigation = (search: string, interval: string | undefined) => {
    const url = `/#gsc.tab=0&gsc.q=${encodeURIComponent(search)}&gsc.sort=date`;
    navigate(url, { state: { query: search } }); // Use the navigation function provided by useNavigation
  };

  return (
    <ListItemText
      primary={
        <span
          onClick={() => handleNavigation(search, interval)}
          style={{
            color: "#007bff",
            cursor: "pointer",
            textDecoration: "underline",
          }}
        >
          {search}
        </span>
      }
      secondary={interval ? `Interval: ${interval}` : null}
    />
  );
}
