import React, { useState, useEffect } from "react";

import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Collapse,
  Container,
  Typography,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";

import AddIcon from "@mui/icons-material/Add";
import { auth, db } from "../config/firebase";

import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import Logout from "../components/auth/Logout";
import { useNavigate } from "react-router-dom";
import { UserSavedSearchesModal } from "../components/UserSavedSearchesModal";

interface WhitelistUser {
  name: string;
  email: string;
  role: "common" | "admin";
}

interface User {
  uid: string;
  name: string;
  email: string;
  role?: "common" | "admin";
}

const AdminPanel: React.FC = () => {
  const [whitelistUsers, setWhitelistUsers] = useState<WhitelistUser[]>([]);

  const [users, setUsers] = useState<User[]>([]);
  const [isWhitelistOpen, setIsWhitelistOpen] = useState(true);
  const [isUsersOpen, setIsUsersOpen] = useState(true);
  const [newUser, setNewUser] = useState<WhitelistUser>({
    name: "",
    email: "",
    role: "common",
  });
  const [isUserSearchesModalOpen, setIsUserSearchesModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const handleSearchButtonClick = (userId: string) => {
    setSelectedUserId(userId);
    setIsUserSearchesModalOpen(true);
  };

  useEffect(() => {
    auth
      .currentUser!.getIdTokenResult()
      .then((idTokenResult) => {
        // Log the entire token result if needed
        console.log(idTokenResult);

        // Log just the custom claims
        console.log(idTokenResult.claims);

        // For example, to check if the user has an 'admin' role
        if (idTokenResult.claims.role === "admin") {
          console.log("User is an admin");
        } else {
          console.log("User is not an admin");
        }
      })
      .catch((error) => {
        console.error("Error getting ID token result:", error);
      });

    // Fetch whitelist users
    const whitelistRef = collection(db, "whitelist");
    getDocs(whitelistRef).then((snapshot) => {
      const fetchedUsers: WhitelistUser[] = [];
      snapshot.forEach((doc) => fetchedUsers.push(doc.data() as WhitelistUser));
      setWhitelistUsers(fetchedUsers);
    });

    // Fetch existing users
    const usersRef = collection(db, "users");
    getDocs(usersRef).then((snapshot) => {
      const fetchedUsers: User[] = [];
      snapshot.forEach((doc) => fetchedUsers.push(doc.data() as User));
      setUsers(fetchedUsers);
    });
  }, []);

  const handleAddUser = () => {
    if (newUser.name && newUser.email) {
      const userDoc = doc(db, "whitelist", newUser.email.toLowerCase());
      setDoc(userDoc, newUser).then(() => {
        setWhitelistUsers((prev) => [...prev, newUser]);
        setNewUser({ name: "", email: "", role: "common" });
      });
    }
  };

  const handleDeleteWhitelistUser = (email: string) => {
    const userDoc = doc(db, "whitelist", email.toLowerCase());
    deleteDoc(userDoc).then(() => {
      setWhitelistUsers((prev) => prev.filter((user) => user.email !== email));
    });
  };

  const handleDeleteUser = async (uid: string) => {
    // Delete user from Firestore
    await deleteDoc(doc(db, "users", uid));

    // Update local state
    setUsers((prevUsers) => prevUsers.filter((user) => user.uid !== uid));
  };
  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Logout />

        <Button variant="contained" onClick={() => navigate("/")}>
          Home
        </Button>
      </Box>
      <Container>
        <Typography variant="h4">Admin Panel</Typography>

        {/* Whitelist Management */}
        <Button onClick={() => setIsWhitelistOpen(!isWhitelistOpen)}>
          Whitelist Users
        </Button>
        <Collapse in={isWhitelistOpen}>
          <TextField
            label="Name"
            value={newUser.name}
            onChange={(e) => setNewUser({ ...newUser, name: e.target.value })}
          />
          <TextField
            label="Email"
            value={newUser.email}
            onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
          />
          <Button onClick={handleAddUser} startIcon={<AddIcon />}>
            Add to Whitelist
          </Button>
          <List>
            {whitelistUsers.map((user) => (
              <ListItem divider key={user.email}>
                <ListItemText primary={user.name} secondary={user.email} />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => handleDeleteWhitelistUser(user.email)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Collapse>

        {/* Existing Users */}
        <Button onClick={() => setIsUsersOpen(!isUsersOpen)}>
          Existing Users
        </Button>
        <Collapse in={isUsersOpen}>
          <List>
            {users.map((user, index) => (
              <ListItem divider key={user.uid}>
                <IconButton
                  edge="start"
                  onClick={() => handleSearchButtonClick(user.uid)}
                >
                  <SearchIcon />
                </IconButton>
                <ListItemText primary={user.name} secondary={user.email} />

                <IconButton
                  edge="end"
                  onClick={() => handleDeleteUser(user.uid)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Collapse>

        <UserSavedSearchesModal
          userId={selectedUserId}
          open={isUserSearchesModalOpen}
          handleClose={() => setIsUserSearchesModalOpen(false)}
        />
      </Container>
    </Box>
  );
};

export default AdminPanel;
