import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CardActions,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { db, auth } from "../config/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  deleteDoc,
  onSnapshot,
  setDoc,
} from "firebase/firestore";
import { SearchLink } from "./SearchLink";

interface QueryItem {
  id: string;
  userId: string;
  query: string;
  interval: string;
}

interface ViewSavedQueriesModalProps {
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
}

const ViewSavedQueriesModal: React.FC<ViewSavedQueriesModalProps> = ({
  handleClose,
  handleOpen,
  open,
}) => {
  const [queries, setQueries] = useState<QueryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [editingQueryId, setEditingQueryId] = useState<string | null>(null);
  const [editingQueryValue, setEditingQueryValue] = useState("");

  const handleEdit = (id: string, query: string) => {
    setEditingQueryId(id);
    setEditingQueryValue(query);
  };

  const handleSave = async (id: string) => {
    try {
      await setDoc(
        doc(db, "savedQueries", id),
        { query: editingQueryValue },
        { merge: true }
      );
      setQueries((prevQueries) =>
        prevQueries.map((query) =>
          query.id === id ? { ...query, query: editingQueryValue } : query
        )
      );
      setEditingQueryId(null);
      setEditingQueryValue("");
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  };

  useEffect(() => {
    let unsubscribe: any;

    if (open) {
      // Only subscribe when the modal is open
      const userId = auth.currentUser?.uid;
      if (!userId) {
        setError("User is not logged in.");
        setLoading(false);
        return;
      }

      const savedQueriesRef = collection(db, "savedQueries");
      const q = query(savedQueriesRef, where("userId", "==", userId));

      // Subscribe to real-time updates
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        try {
          const queriesArray: QueryItem[] = [];
          querySnapshot.forEach((doc) => {
            queriesArray.push({ id: doc.id, ...doc.data() } as QueryItem);
          });

          setQueries(queriesArray);
          setLoading(false);
        } catch (error) {
          setError("An error occurred while fetching the queries.");
          console.error(error);
          setLoading(false);
        }
      });
    }

    // Clean up the subscription when modal closes or before creating a new one
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [open, setLoading, setQueries, setError]);

  const handleDelete = useCallback(
    async (id: string) => {
      try {
        await deleteDoc(doc(db, "savedQueries", id));
        setQueries((prevQueries) =>
          prevQueries.filter((query) => query.id !== id)
        );
      } catch (error) {
        console.error("Error deleting document: ", error);
      }
    },
    [setQueries]
  );

  const body = (
    <Card
      sx={{
        position: "absolute",
        width: 400,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        overflow: "scroll",
        maxHeight: "80vh",
      }}
    >
      <CardContent>
        <Typography variant="h4" sx={{ mb: 2 }} component="div">
          Your Saved Queries
        </Typography>
        {loading ? (
          <Typography>Loading...</Typography>
        ) : (
          <List>
            {queries.map((queryItem) => (
              <ListItem key={queryItem.id}>
                {editingQueryId === queryItem.id ? (
                  <TextField
                    value={editingQueryValue}
                    onChange={(e) => setEditingQueryValue(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSave(queryItem.id);
                        e.preventDefault(); // Prevents the default action (form submission, newline, etc.)
                      }
                    }}
                  />
                ) : (
                  <SearchLink
                    search={queryItem.query}
                    interval={queryItem.interval}
                  />
                )}
                <ListItemSecondaryAction>
                  {editingQueryId !== queryItem.id ? (
                    <IconButton
                      edge="end"
                      onClick={() => handleEdit(queryItem.id, queryItem.query)}
                    >
                      <EditIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      onClick={() => handleSave(queryItem.id)}
                    >
                      <SaveIcon />
                    </IconButton>
                  )}
                  {editingQueryId === queryItem.id ? (
                    <IconButton
                      edge="end"
                      onClick={() => {
                        setEditingQueryId(null);
                        setEditingQueryValue("");
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      edge="end"
                      onClick={() => handleDelete(queryItem.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
        <Typography color="red" variant="h5" component="div">
          {error}
        </Typography>
      </CardContent>
      <CardActions>
        <Button onClick={handleClose} variant="outlined">
          Close
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        View Saved Queries
      </Button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default ViewSavedQueriesModal;
