import { useEffect, useState } from "react";
import {
  Modal,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Typography,
  Button,
  ListItemIcon,
} from "@mui/material";
import { db } from "../config/firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";
import { SearchLink } from "./SearchLink";

interface UserSavedSearchesModalProps {
  userId: string | null;
  handleClose: () => void;
  open: boolean;
}

export const UserSavedSearchesModal: React.FC<UserSavedSearchesModalProps> = ({
  userId,
  handleClose,
  open,
}) => {
  const [savedSearches, setSavedSearches] = useState<string[]>([]);

  useEffect(() => {
    if (userId) {
      const fetchSavedSearches = async () => {
        const savedQueriesRef = collection(db, "savedQueries");
        const q = query(savedQueriesRef, where("userId", "==", userId));

        const querySnapshot = await getDocs(q);
        const userSearches: string[] = [];
        querySnapshot.forEach((doc) => userSearches.push(doc.data().query));

        setSavedSearches(userSearches);
      };

      fetchSavedSearches();
    }
  }, [userId]);

  const body = (
    <Card
      sx={{
        position: "absolute",
        width: 400,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <CardContent>
        <Typography variant="h4" component="div">
          User's Saved Searches
        </Typography>
        <List>
          {savedSearches.map((search, index) => (
            <div key={index}>
              <ListItem>
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <SearchLink search={search} />
              </ListItem>
              {index !== savedSearches.length - 1 && <Divider />}{" "}
              {/* Add divider except for the last item */}
            </div>
          ))}
        </List>
      </CardContent>
      <Button onClick={handleClose} variant="outlined">
        Close
      </Button>
    </Card>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {body}
    </Modal>
  );
};
