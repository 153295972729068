import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";

interface Props {
  navigateTo?: string;
}

const Logout = ({ navigateTo = "/login" }: Props) => {
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    setDisabled(true);
    signOut(auth)
      .then(() => {
        navigate(navigateTo);
      })
      .catch((error) => {
        console.error(error);
        setDisabled(false);
      });
  };

  return (
    // <Box
    //   sx={{
    //     // flexBasis: "25%",
    //     justifySelf: "flex-start",
    //   }}
    // >
    <Button disabled={disabled} onClick={logout}>
      Logout
    </Button>
    // </Box>
  );
};

export default Logout;
