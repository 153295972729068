import { useEffect, useState } from "react";
import Logout from "../components/auth/Logout";

import { Box, Button } from "@mui/material";
import SaveSearchModal from "../components/SaveSearchModal";
import ViewSavedQueriesModal from "../components/ViewSavedQueriesModal";
import { auth } from "../config/firebase";
import { useLocation, useNavigate } from "react-router-dom";
import { SalesToolModal } from "../components/SalesToolModal";
import "./home.css";

interface Props {}
declare global {
  interface Window {
    google: any;
  }
}

export function Home() {
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [openView, setOpenView] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();
  const stateQuery = location.state?.query;
  // console.log("queryValue", location.state);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenView = () => {
    setOpenView(true);
  };

  const handleCloseView = () => {
    setOpenView(false);
  };

  useEffect(() => {
    auth
      .currentUser!.getIdTokenResult()
      .then((idTokenResult) => {
        // Log the entire token result if needed
        // console.log(idTokenResult);

        // Log just the custom claims
        // console.log(idTokenResult.claims);

        // For example, to check if the user has an 'admin' role
        if (idTokenResult.claims.role === "admin") {
          setIsAdmin(true);
        } else {
          setIsAdmin(false);
        }
      })
      .catch((error) => {
        console.error("Error getting ID token result:", error);
      });
  }, []);

  useEffect(() => {
    setInterval(() => {
      // const currentQuery = new URLSearchParams(window.location.hash);
      const inputElement: HTMLInputElement = document.getElementById(
        "gsc-i-id1"
      ) as HTMLInputElement;
      const inputValue = inputElement?.value;
      // if (currentQuery.has("gsc.q")) {
      //   const query = currentQuery.get("gsc.q");
      //   console.log("query", query);
      //   // decodes the url encoded query
      //   setQuery(decodeURIComponent(query || ""));
      // }
      if (inputValue) {
        setQuery(inputValue);
      }
    }, 200);
  }, []);

  useEffect(() => {
    // Check for the CSE iframe to load
    const checkExist = setInterval(() => {
      const links = document.querySelectorAll(
        // ".gsc-results .gsc-thumbnail-inside a.gs-title"
        "a.gs-title:link"
      );
      // console.log("links", links);
      if (links && links.length) {
        links.forEach((link) => {
          link.setAttribute("target", "_blank");
        });
        // clearInterval(checkExist);
      }
    }, 100);

    // Cleanup interval when component is unmounted
    return () => clearInterval(checkExist);
  }, []);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cse.google.com/cse.js?cx=25a258d5a2e5f4fbd";
    script.async = true;
    script.onload = () => {
      if (stateQuery) {
        var element =
          window.google.search.cse.element.getElement("google-search");
        if (element) {
          element.execute(stateQuery);
        }
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [stateQuery]); // Empty array ensures effect is only run on mount and unmount
  useEffect(() => {
    if (
      window.google &&
      window.google.search &&
      window.google.search.cse &&
      window.google.search.cse.element &&
      window.google.search.cse.element.getElement
    ) {
      var element =
        window.google.search.cse.element.getElement("google-search");
      if (element) {
        element.execute(stateQuery);
      }
    }
  }, [stateQuery]);

  const navigate = useNavigate();
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Logout />
          {isAdmin && (
            <Button variant="contained" onClick={() => navigate("/admin")}>
              Admin Panel
            </Button>
          )}
          <SalesToolModal />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          <ViewSavedQueriesModal
            handleClose={handleCloseView}
            handleOpen={handleOpenView}
            open={openView}
          />
          <SaveSearchModal
            handleClose={handleClose}
            handleOpen={handleOpen}
            open={open}
            queryString={query}
          />
        </Box>
      </Box>
      <div
        style={{ display: "flex", justifyContent: "center", paddingBottom: 8 }}
      >
        <img
          height="60"
          src="https://crbworkforce.com/wp-content/themes/crb-workforce/assets/dist/img/logo.svg"
          alt="CRB Logo"
        />
      </div>

      <div data-gname="google-search" className="gcse-search"></div>
    </Box>
  );
}
