import { useCallback, useEffect, useState } from "react";
import {
  Modal,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
} from "@mui/material";

import { db, auth } from "../config/firebase";
import { collection, addDoc, query, where, getDocs } from "firebase/firestore";
import { customToLowerCase } from "../utils";

/**
 * TODO: Add check for if query exists for user
 * overwrite if interval is different, otherwise do nothing
 * And add page to view all saved queries
 *
 */
const SaveSearchModal = ({
  queryString,
  handleClose,
  handleOpen,
  open,
}: {
  queryString: string;
  handleClose: () => void;
  handleOpen: () => void;
  open: boolean;
}) => {
  const [interval, setInterval] = useState("1 day");
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setError("");
    setSuccess(false);
    setDisabled(false);
  }, [queryString]);

  // Get a reference to the Firestore service
  /**
   * Save a new search query for a user.
   *
   * @param {string} userId - The ID of the user.
   * @param {string} queryString - The search query.
   * @returns {Promise<void>}
   */
  const saveSearchQuery = useCallback(
    async (queryString: string) => {
      setDisabled(true);
      const userId = auth.currentUser?.uid;
      if (!userId) {
        setError("User is not logged in.");
        setSuccess(false);
        setDisabled(false);

        return;
      }
      queryString = customToLowerCase(queryString);
      try {
        const savedQueriesRef = collection(db, "savedQueries");
        const q = query(
          savedQueriesRef,
          where("userId", "==", auth.currentUser?.uid),
          where("query", "==", queryString)
        );

        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          setError("The search query already exists for this user.");
          setSuccess(false);
          setDisabled(false);
          return;
        }

        // Save the new search query
        await addDoc(savedQueriesRef, {
          userId: userId,
          query: queryString,
          interval: "daily",
          email: auth.currentUser?.email,
        });
        setSuccess(true);
      } catch (error) {
        setError("An error occurred while saving the query");
        setSuccess(false);
        setDisabled(false);
        console.log(error);
      }
    },
    [setDisabled, setError, setSuccess]
  );

  const body = (
    <Card
      sx={{
        position: "absolute",
        width: 400,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
      }}
    >
      <CardContent>
        <Typography variant="h4" sx={{ mb: 2 }} component="div">
          Save Query to Get Email Alerts
        </Typography>
        <Typography variant="h5" component="div">
          {queryString}
        </Typography>
        <Typography color={"red"} variant="h5" component="div">
          {error}
        </Typography>
        <Typography color={"green"} variant="h5" component="div">
          {success && "Query saved successfully"}
        </Typography>
        {/* <Box sx={{ display: "flex", mt: 4 }}>
          <Typography variant="h6" component="div" sx={{ width: "100%" }}>
            Alert Interval
          </Typography>
          <Select value={interval} onChange={handleIntervalChange}>
            <MenuItem value="1 day">1 day</MenuItem>
            <MenuItem value="1 week">1 week</MenuItem>
            <MenuItem value="1 month">1 month</MenuItem>
          </Select>
        </Box> */}
      </CardContent>
      <CardActions>
        <Button
          disabled={disabled}
          onClick={() => saveSearchQuery(queryString)}
          variant="contained"
        >
          Save
        </Button>
        <Button onClick={handleClose} variant="outlined">
          {success ? "Ok" : "Cancel"}
        </Button>
      </CardActions>
    </Card>
  );

  return (
    <div>
      <Button onClick={handleOpen} disabled={!queryString} variant="contained">
        Save Query
      </Button>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </div>
  );
};

export default SaveSearchModal;
