import { Home } from "../screens/Home";
import Login from "../screens/Login";
import AdminPanel from "../screens/AdminPanel";

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: "",
    component: Home,
    name: "Home Screen",
    protected: true,
  },
  {
    path: "/login",
    component: Login,
    name: "Login Screen",
    protected: false,
  },
  {
    path: "/admin",
    component: AdminPanel,
    name: "Admin Panel",
    protected: true,
  },
];

export default routes;
