import React, { useState } from "react";
import {
  Modal,
  Button,
  Typography,
  Card,
  CardContent,
  List,
  ListItem,
} from "@mui/material";

export const SalesToolModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpen}>
        About
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            maxHeight: "80vh",
            overflowY: "scroll",
            p: 4,
          }}
        >
          <CardContent>
            <Typography variant="h4" gutterBottom>
              Hi there!
            </Typography>
            <Typography variant="h5">
              Welcome to Version 1 of the next sales recruiting tool that will
              revolutionize the way you do business development.
            </Typography>
            <Typography variant="body1" gutterBottom>
              You are part of the exclusive group that has access to this
              powerful engine. The goal is simple: Clear the clutter and find
              the customers that need you lightning fast.
            </Typography>
            <Typography variant="body2">
              How do we do it? We built software that isolates several core ATS
              systems, notifies you of new listings, and allows you to search
              both titles and job descriptions. Skip google, skip LinkedIn, skip
              Indeed and go straight to the companies career page!
            </Typography>
            <Typography variant="h6" gutterBottom>
              Our current list of ATS systems that are integrated are:
            </Typography>
            <List>
              {[
                "ADP WorkforceNow",
                "AshbyHQ",
                "Breezy",
                "Comeet",
                "GoHire",
                "Greenhouse",
                "JazzHR",
                "Jobvite",
                "Lever",
                "Rippling",
                "Smart Recruiters",
                "Workable",
                "Workday",
              ].map((ats, index) => (
                <ListItem key={index}>
                  <Typography variant="body2">{ats}</Typography>
                </ListItem>
              ))}
            </List>
            <Typography variant="body1" gutterBottom>
              If you have a recommendation you would like to see added – please
              send it to{" "}
              <a href="mailto:Notifications@crbworkforce.com">
                Notifications@crbworkforce.com
              </a>
            </Typography>
            <Typography variant="h6" gutterBottom>
              Here are a few pointers:
            </Typography>
            <Typography variant="body2" gutterBottom>
               Boolean logic applies, however you do not need to use it. A
              simple key word search will work.
            </Typography>
            <Typography variant="body2" gutterBottom>
               If you wish to search only titles – use this function
              intitle:keyword (intitle must be lowercase and no space after the
              “:”
            </Typography>
            <Typography variant="body2">
               Don't forget to save your search, and we will send you daily
              updates of new jobs that are posted.
            </Typography>
          </CardContent>
          <Button onClick={handleClose} variant="outlined">
            Close
          </Button>
        </Card>
      </Modal>
    </div>
  );
};
